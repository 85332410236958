<template>
  <div class="modal basic" role="dialog" v-body-scroll-lock="true">
    <div class="container">
      <div @click="close" class="menu-close" data-dismiss="modal" aria-label="Close">
        <span class="menu-close-icon" />
      </div>
      <h2 class="modal-title">
        Add Beneficiary
      </h2>

      <div class="modal-forms-wrapper">
        <div class="fixed-navigation">
          <ol class="fixed-navigation-list">
            <li class="fixed-navigation-item" :class="{active: step === 0}" @click="moveToStep(0)">
              <span>Beneficiary details</span>
            </li>
            <li class="fixed-navigation-item" :class="{active: step === 1}" @click="moveToStep(1)">
              <span>Review</span>
            </li>
          </ol>
        </div>
        <div class="modal-scrollable-wrapper">
          <div class="modal-content active" v-if="step === 0">
            <div class="modal-content-section">
              <form name="create-beneficiary" action="" @submit.prevent="submit">
                <h3 class="modal-content-title">
                  Beneficiary
                </h3>

                <div class="modal-content-inner" :class="{'no-border': hasTabs}">
                  <div class="grid">
                    <div class="col-6">
                      <form-group :error="errors.currency_id" label="Currency">
                        <vue-multiselect @close="validate('currency_id')" v-model="form.currency" :class="{'valid': form.currency}" :options="currencies" :allow-empty="false" :show-labels="false" :custom-label="getCurrencyCustomLabel">
                          <template slot="option" slot-scope="props">
                            {{ props.option.label }} ({{ props.option.id }})
                          </template>
                          <template slot="singleLabel" slot-scope="props">
                            {{ props.option.label }} ({{ props.option.id }})
                          </template>
                        </vue-multiselect>
                      </form-group>
                    </div>
                    <div class="col-6">
                      <form-group label="Type" :error="errors.entity_type">
                        <vue-multiselect @close="validate('entity_type')" v-model="form.type" :preselect-first="true" :class="{'valid': form.type}" :options="types" :allow-empty="false" :show-labels="false" :searchable="false" label="label" />
                      </form-group>
                    </div>
                  </div>
                </div>

                <tabs
                    v-if="hasTabs && isFirstStepCompleted"
                    :items="getTabs"
                    v-model="activeTab"
                    class="types-tabs"
                />

                <div class="modal-content-inner">
                  <div class="grid">
                    <template v-if="isIndividual">
                      <div class="col-6" key="first_name">
                        <form-group label="First name" :error="errors.first_name">
                          <input @change="validate('first_name')" v-model="form.first_name" :class="{'valid': form.first_name}" class="form-control" type="text">
                        </form-group>
                      </div>
                      <div class="col-6" key="last_name">
                        <form-group label="Last name" :error="errors.last_name">
                          <input @change="validate('last_name')" v-model="form.last_name" :class="{'valid': form.last_name}" class="form-control" type="text">
                        </form-group>
                      </div>
                    </template>
                    <template v-else>
                      <div class="col-6" key="company_name">
                        <form-group label="Company name" :error="errors.company_name">
                          <input @change="validate('company_name')" v-model="form.company_name" :class="{'valid': form.company_name}" class="form-control" type="text">
                        </form-group>
                      </div>
                    </template>
                  </div>
                </div>

                <transition name="fade" :duration="200">
                  <div class="modal-content-inner" v-if="isFirstStepCompleted">
                    <div class="grid">
                      <!-- Is SEPA, SWIFT -->
                      <template v-if="!isLocalPayment">
                        <div class="col-6">
                          <form-group label="IBAN" :error="errors.iban" label-class="control-label notranslate">
                            <input @change="validate('iban')" v-model="form.iban" :class="{'valid': form.iban}" class="form-control" type="text">
                            <div class="hint-block" v-if="displayNoticeMessage('iban').length > 0">
                              {{ displayNoticeMessage('iban') }}
                            </div>
                          </form-group>
                        </div>
                        <div class="col-6">
                          <form-group label="BIC/SWIFT" :error="errors.bic_swift" label-class="control-label notranslate">
                            <input @change="validate('bic_swift')" v-model="form.bic_swift" :class="{'valid': form.bic_swift}" class="form-control" type="text">
                          </form-group>
                        </div>
                      </template>

                      <!-- Is GBP Local transfer -->
                      <template v-else>
                        <div class="col-6">
                          <form-group label="Account number" :error="errors.account_number">
                            <input @change="validate('account_number')" v-model="form.account_number" :class="{'valid': form.account_number}" class="form-control" type="text">
                          </form-group>
                        </div>
                        <div class="col-6">
                          <form-group label="Sort Code" :error="errors.sort_code">
                            <input @change="validate('sort_code')" v-model="form.sort_code" :class="{'valid': form.sort_code}" class="form-control" type="text">
                          </form-group>
                        </div>
                      </template>

                      <div class="col-6" v-if="canDisplayBankCountry">
                        <form-group label="Bank country" :error="errors.bank_country">
                          <vue-multiselect @close="validate('bank_country')" v-model="form.bank_country" :class="{'valid': form.bank_country, 'notranslate': true}" :options="countries" :allow-empty="false" :show-labels="false" label="label" />
                        </form-group>
                      </div>

                      <div class="col-6">
                        <form-group :error="errors.name" label="Template name">
                          <input @change="validate('name')" v-model="form.name" :class="{'valid': form.name}" type="text" class="form-control">
                        </form-group>
                      </div>
                    </div>
                  </div>
                </transition>

                <transition name="fade" :duration="200">
                  <div v-if="canDisplayAddress" class="modal-content-inner">
                    <div class="grid">
                      <div class="col-6">
                        <form-group label="Country" :error="errors.country_id">
                          <vue-multiselect @close="validate('country_id')" v-model="form.country" :class="{'valid': form.country, 'notranslate': true}" :options="countries" label="label" :allow-empty="false" :show-labels="false" />
                        </form-group>
                      </div>
                      <div class="col-6">
                        <form-group label="City" :error="errors.city">
                          <input @change="validate('city')" v-model="form.city" :class="{'valid': form.city}" class="form-control" type="text">
                        </form-group>
                      </div>
                      <div class="col-6">
                        <form-group label="Street" :error="errors.street">
                          <input @change="validate('street')" v-model="form.street" :class="{'valid': form.street}" class="form-control" type="text">
                        </form-group>
                      </div>
                      <div class="col-3">
                        <form-group label="House" :error="errors.house">
                          <input @change="validate('house')" v-model="form.house" :class="{'valid': form.house}" class="form-control" type="text">
                        </form-group>
                      </div>
                      <div class="col-3">
                        <form-group label="Postal code" :error="errors.postal_code">
                          <input @change="validate('postal_code')" v-model="form.postal_code" :class="{'valid': form.postal_code}" class="form-control" type="text">
                        </form-group>
                      </div>
                    </div>
                  </div>
                </transition>

                <div class="modal-content-bottom-panel">
                  <p @click="moveToStep(1)" class="modal-content-bottom-panel-copy next-form-button">
                    <span>Review</span>
                    <img src="@/assets/images/icons/ic_next.svg">
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-content" v-if="step === 1">
            <div class="modal-content-section">
              <h3 class="modal-content-title">
                Basic info
              </h3>
              <div class="modal-content-inner">
                <div class="grid">
                  <div class="col-4">
                    <p class="modal-inner-title">
                      Currency
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.currency.label }} ({{ form.currency.id }})
                    </p>
                  </div>
                  <div class="col-4">
                    <p class="modal-inner-title">
                      Type
                    </p>
                    <p class="modal-inner-copy notranslate" style="text-transform: capitalize">
                      {{ form.type.label }}
                    </p>
                  </div>
                  <div class="col-4">
                    <p class="modal-inner-title">
                      Template name
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.name }}
                    </p>
                  </div>
                </div>
                <div class="grid">
                  <template v-if="!isLocalPayment">
                    <div class="col-4">
                      <p class="modal-inner-title notranslate">
                        BIC/SWIFT
                      </p>
                      <p class="modal-inner-copy notranslate">
                        {{ form.bic_swift }}
                      </p>
                    </div>
                    <div class="col-8">
                      <p class="modal-inner-title notranslate">
                        IBAN
                      </p>
                      <p class="modal-inner-copy notranslate">
                        {{ form.iban }}
                      </p>
                    </div>
                  </template>
                  <template v-else>
                    <div class="col-4">
                      <p class="modal-inner-title">
                        Account number
                      </p>
                      <p class="modal-inner-copy notranslate">
                        {{ form.account_number }}
                      </p>
                    </div>
                    <div class="col-4">
                      <p class="modal-inner-title">
                        Sort Code
                      </p>
                      <p class="modal-inner-copy notranslate">
                        {{ form.sort_code }}
                      </p>
                    </div>
                  </template>
                  <div class="col-4" v-if="form.type.id === 'individual'">
                    <p class="modal-inner-title">
                      Person name
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.first_name }} {{ form.last_name }}
                    </p>
                  </div>
                  <div class="col-4" v-if="form.type.id === 'company'">
                    <p class="modal-inner-title">
                      Company name
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.company_name }}
                    </p>
                  </div>
                  <div class="col-4" v-if="form.bank_country">
                    <p class="modal-inner-title">
                      Bank country
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.bank_country.label }} ({{ form.bank_country.id }})
                    </p>
                  </div>
                </div>
              </div>

              <template v-if="canDisplayAddress">
                <h3 class="modal-content-title">
                  Address
                </h3>
                <div class="modal-content-inner">
                  <div class="grid">
                    <div class="col-4">
                      <p class="modal-inner-title">
                        Country
                      </p>
                      <p class="modal-inner-copy notranslate">
                        {{ form.country.label }} ({{ form.country.id }})
                      </p>
                    </div>
                    <div class="col-4">
                      <p class="modal-inner-title">
                        City
                      </p>
                      <p class="modal-inner-copy notranslate">
                        {{ form.city }}
                      </p>
                    </div>
                    <div class="col-4">
                      <p class="modal-inner-title">
                        Street
                      </p>
                      <p class="modal-inner-copy notranslate">
                        {{ form.street }}
                      </p>
                    </div>
                  </div>
                  <div class="grid">
                    <div class="col-4">
                      <p class="modal-inner-title">
                        House
                      </p>
                      <p class="modal-inner-copy notranslate">
                        {{ form.house }}
                      </p>
                    </div>
                    <div class="col-4">
                      <p class="modal-inner-title">
                        Postal code
                      </p>
                      <p class="modal-inner-copy notranslate">
                        {{ form.postal_code }}
                      </p>
                    </div>
                  </div>
                </div>
              </template>

              <div class="modal-content-bottom-panel">
                <button type="button" @click="submit" class="modal-content-bottom-panel-copy next-form-button">
                  <span>Save</span>
                  <img src="@/assets/images/icons/ic_next.svg">
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import FormGroup from '@/libs/forms/FormGroup'
import Tabs from "@/libs/Tabs";

const FORM_TYPE_DEFAULT = 'DEFAULT'
const FORM_TYPE_LOCAL = 'LOCAL'

export default {
  name: 'BeneficiaryCreateModal',
  components: {
    Tabs,
    VueMultiselect,
    FormGroup
  },
  data () {
    return {
      step: 0,
      form: {
        name: '',
        currency: '',

        iban: '',
        bic_swift: '',

        account_number: '',
        sort_code: '',

        country: '',
        bank_country: '',
        city: '',
        street: '',
        postal_code: '',
        house: '',

        type: '',
        first_name: '',
        last_name: '',
        company_name: ''
      },

      errors: [],
      notice: [],

      currencies: [],
      countries: [],

      types: [
        {
          id: 'individual',
          label: 'Individual'
        },
        {
          id: 'company',
          label: 'Company'
        }
      ],

      activeTab: null,
      activeTabDefault: {
        id: FORM_TYPE_DEFAULT,
        label: 'International'
      },

      tabs: {
        'GBP': [
          {
            id: FORM_TYPE_LOCAL,
            label: 'Local'
          },
          {
            id: FORM_TYPE_DEFAULT,
            label: 'International'
          }
        ]
      }
    }
  },
  watch: {
    'activeTab': function (val) {
      this.resetForm()
    },
    'form.iban': function (val) {
      this.form.iban = val.trim()
    },
    'form.account_number': function (val) {
      this.form.account_number = val.trim()
    },
    'form.currency': function (newValue, oldValue) {
      this.resetForm()

      const id = newValue.id

      if (id && this.tabs[id] && this.tabs[id].length > 0) {
        this.activeTab = [...this.tabs[id]].shift()
      } else {
        this.activeTab = {...this.activeTabDefault}
      }
    }
  },
  computed: {

    isFirstStepCompleted () {
      return !!(
          this.form.currency
          && this.form.type
          // && (this.isCompany ? this.form.company_name : (this.form.first_name && this.form.last_name))
      )
    },

    hasTabs () {
      return this.getTabs.length > 1
    },

    getTabs () {
      return this.tabs[this.form.currency.id] ?? []
    },

    canDisplayBankCountry () {
      return this.isFirstStepCompleted
          && this.activeTab.id !== FORM_TYPE_LOCAL
    },

    canDisplayAddress () {
      return this.isFirstStepCompleted
          // && !(this.form.currency.id === 'GBP' && this.activeTab === FORM_TYPE_LOCAL)
          && !(this.form.currency.id === 'EUR' && this.ibanInfo.is_sepa !== false)
    },

    isCompany () {
      return this.form.type && (this.form.type.id === 'company')
    },

    isIndividual () {
      return this.form.type && (this.form.type.id === 'individual')
    },

    /**
     * Check is it local payment in GBP and bank country United Kingdom
     * @return {boolean}
     */
    isLocalPayment () {
      return this.form.currency
          && this.form.currency.id === 'GBP'
          && this.activeTab.id === FORM_TYPE_LOCAL
    },

    /**
     * Prepare data for submit
     */
    formData () {
      return {
        // Basic fields
        name: this.form.name,
        currency_id: this.form.currency ? this.form.currency.id : '',

        // Address fields
        country_id: this.form.country ? this.form.country.id : undefined,
        city: this.form.city || undefined,
        street: this.form.street || undefined,
        postal_code: this.form.postal_code || undefined,
        house: this.form.house || undefined,

        // Beneficiary fields
        entity_type: this.form.type ? this.form.type.id : '',
        first_name: this.isIndividual ? this.form.first_name : undefined,
        last_name: this.isIndividual ? this.form.last_name : undefined,
        company_name: this.isCompany ? this.form.company_name : undefined,

        // Additional fields
        bank_country: this.isLocalPayment ? undefined : (this.form.bank_country ? this.form.bank_country.id : ''),
        bank_details_type: this.activeTab.id,

        iban: !this.isLocalPayment ? this.form.iban : undefined,
        bic_swift: !this.isLocalPayment ? this.form.bic_swift : undefined,

        account_number: this.isLocalPayment ? this.form.account_number : undefined,
        sort_code: this.isLocalPayment ? this.form.sort_code : undefined
      }
    }
  },
  beforeCreate () {
    this.$sdk.beneficiary.getCurrencies().then(response => {
      this.currencies = response.data

      // Preselect first currency value
      if (this.currencies.length > 0 && Array.isArray(this.currencies)) {
        this.form.currency = [...this.currencies].shift()
      }
    })

    this.$sdk.getCountries().then(response => {
      this.countries = response.data
    })
  },

  asyncComputed: {
    ibanInfo: {
      get () {
        if (this.form.iban.length === 0) {
          return this.getIbanInfoDefault()
        }

        let iban = this.formatIban(this.form.iban)
        return this.$sdk.beneficiary.getIbanInfo(iban)
          .then((response) => {
            const data = response.data

            if (data.bic_swift && data.bic_swift.length > 0) {
              this.form.bic_swift = data.bic_swift
            } else {
              this.form.bic_swift = ''
            }

            if (data.country_id && data.country_id.length > 0) {
              this.form.bank_country = this.countries.find(i => {
                return i.id === data.country_id
              })
            } else {
              this.form.bank_country = ''
            }

            return data
          })
      },
      default () {
        return this.getIbanInfoDefault()
      },
      shouldUpdate () {
        return this.isFirstStepCompleted
      }
    }
  },
  methods: {
    /**
     * Prepare iban for sending to iban details ajax request
     *
     * @param iban
     * @returns {*|string}
     */
    formatIban (iban) {
      return iban.trim().replaceAll(' ', '')
    },

    getIbanInfoDefault() {
      return {
        is_sepa: null,
        bic_swift: '',
        country_id: ''
      }
    },

    resetForm () {
      // Reset errors
      this.errors = []

      // Reset notice message
      this.notice = []

      // Reset form fields
      this.form.name = ''

      this.form.iban = ''
      this.form.bic_swift = ''
      this.form.account_number = ''
      this.form.sort_code = ''
      this.form.country = ''
      this.form.bank_country = ''
      this.form.city = ''
      this.form.street = ''
      this.form.postal_code = ''
      this.form.house = ''

      this.form.first_name = ''
      this.form.last_name = ''
      this.form.company_name = ''
    },

    /**
     * Formatting string for search currency
     *
     * @param id
     * @param name
     * @returns {string}
     */
    getCurrencyCustomLabel: function ({ id, label }) {
      return `${label} (${id})`
    },

    /**
     * Form submitting
     */
    submit: function () {
      return this.send()
    },

    /**
     * Prepare and send request
     */
    send: async function (validate = 0, attributes = []) {
      await this.$nextTick()

      const data = this.formData
      const prms = {
        params: {
          validate: validate
        }
      }

      return this.$sdk.beneficiary.create(data, prms).then(response => {
        if (validate) {
          if (attributes.length > 0) {
            for (const attribute of attributes) {
              if (response.data.errors[attribute]) {
                this.$set(this.errors, attribute, response.data.errors[attribute])
              } else {
                this.$delete(this.errors, attribute)
              }
            }
          } else {
            for (const attribute in response.data.errors) {
              if (Object.prototype.hasOwnProperty.call(response.data.errors, attribute)) {
                this.$set(this.errors, attribute, response.data.errors[attribute])
              }
            }
          }
        } else {
          if (Object.keys(response.data.errors).length > 0) {
            for (const attribute in response.data.errors) {
              if (Object.prototype.hasOwnProperty.call(response.data.errors, attribute)) {
                this.$set(this.errors, attribute, response.data.errors[attribute])
              }
            }
          } else {
            this.close()
            this.$notice.success('Beneficiary was created successfully')
          }
        }

        this.notice = response.data.notice

        return response
      }).catch(() => {
        this.$notice.error('Something was wrong. Please reload page and try again')
      })
    },

    /**
     * Form validating
     *
     * @param attributes string|array
     */
    validate: function (attributes) {
      if (typeof attributes === 'string') {
        return this.send(1, [attributes])
      } else {
        return this.send(1, attributes)
      }
    },

    /**
     * Display notice message
     */
    displayNoticeMessage: function (attribute) {
      if (Object.prototype.hasOwnProperty.call(this.notice, attribute) && (this.notice[attribute].length > 0)) {
        return this.notice[attribute][0]
      }

      return ''
    },

    /**
     * Closing modal window
     */
    close () {
      this.$emit('close')
    },

    moveToStep (step) {
      if (step === 1) {
        this.validate().then((response) => {
          if (
              response
              && response.data
              && Object.keys(response.data.errors).length === 0
          ) {
            this.step = step
          }

          return response
        })
      } else {
        this.step = step
      }
    },

  }
}
</script>

<style type="text/css" lang="less">
.no-border {
  padding-bottom: 0;
  border-bottom: 0;
}

.types-tabs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10%, 1fr));
  gap: 0;
  margin: 40px 0 30px;

  li {
    display: inline-block;
    text-align: center;
    padding-bottom: 11px;

    &.active {
      padding-bottom: 10px;
      color: #FDE46A;
    }
  }
}
</style>
