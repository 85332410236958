<template>
  <div v-cloak class="modal modal-wrapper small opened" v-body-scroll-lock="true">
    <div class="container">
      <div @click="close" class="menu-close" data-dismiss="modal" aria-label="Close">
        <span class="menu-close-icon" />
      </div>
      <div class="modal-content">

        <div class="modal-content-section">
          <h3 class="modal-content-title">
            Confirmation required
          </h3>

          <div class="modal-content-inner">
            <div style="line-height: 1.4em;">
              Are you sure you want to remove the beneficiary?
            </div>
          </div>

          <div class="modal-content-bottom-panel">
            <button class="default-button" @click="deleteBeneficiary(id)">Yes</button>
            <button class="default-button" @click="close">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BeneficiaryDeleteConfirm',
  props: ['id'],
  methods: {
    close () {
      this.$emit('close')
    },
    deleteBeneficiary: function (id) {
      this.$sdk.beneficiary.delete(id).then(response => {
        if (response.data.success) {
          this.$notice.success('Beneficiary was successfully deleted')
        }

        this.close()
      }).catch(() => {
        this.$notice.error('Something was wrong. Please reload page and try again')
      })
    },
  }
}
</script>
