<template>
  <div class="main-content-grid-item"
       :class="{opened: hovered}"
       @click="hovered = true"
       @mouseenter="hovered = true"
       @mouseleave="hovered = false"
  >
    <div>
      <span class="corner-info notranslate" v-if="item.address">{{ item.address.country_id }}</span>
      <div class="item-icon">
        <flag :name="item.currency.id" />
      </div>
      <p class="item-copy">
        {{ item.currency.name }} (<span class="notranslate">{{ item.currency.id }}</span>)
      </p>
      <p class="item-big-copy notranslate">
        {{ item.name }}
      </p>
    </div>
    <div class="main-content-expanded-options">
      <div class="options-wrapper">
        <div class="expanded-options-item" v-if="permissions.canPaymentDraftCreate">
          <button @click.stop="showCreatePaymentModal(item.id)">Pay</button>
        </div>
        <div class="expanded-options-item yellow-colored" data-target="delete-option">
          <button @click.stop="showBeneficiaryDetails(item.id)" data-role="link-show">Show</button>
        </div>
        <div class="expanded-options-item red-colored" data-target="delete-option" v-if="permissions.canBeneficiaryDelete">
          <button @click.stop="showBeneficiaryDeleteModal(item.id)" data-target="delete-option" class="expanded-options-item red-colored">Delete</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Flag from "../../libs/Flag";
import BeneficiaryDetailsModal from "../modals/BeneficiaryDetailsModal";
import BeneficiaryDeleteConfirm from "./BeneficiaryDeleteConfirm";
import PaymentCreateModal from "../modals/PaymentCreateModal";
import {mapGetters} from "vuex";

export default {
  name: 'BeneficiaryCard',
  props: ['item'],
  components: {
    Flag
  },
  data () {
    return {
      hovered: false
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'permissions'
    }),
  },
  methods: {
    showBeneficiaryDetails: function (id) {
      this.$modal.show(BeneficiaryDetailsModal, { id: id })
    },
    showBeneficiaryDeleteModal: function (id) {
      this.$modal.show(BeneficiaryDeleteConfirm, { id: id })
    },
    showCreatePaymentModal: function (id) {
      this.$modal.show(PaymentCreateModal, {
        beneficiaryId: id
      })
    },
  }
}
</script>
