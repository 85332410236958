<template>
  <div class="stripe-panel-search" v-click-outside="searchOutsideClick">
    <div class="stripe-panel-search-input" @click="searchOpen">
      <label v-show="!isSearchActive">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path fill="#fff" d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </label>

      <input v-show="isSearchActive" type="text" :placeholder="placeholder" ref="searchRef" v-model="search">

      <div v-if="isSearchActive" @click="searchClose" class="menu-close">
        <span class="menu-close-icon" />
      </div>
    </div>
    <div class="stripe-panel-search-other" v-if="!isSearchActive">
      <slot />
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
export default {
  directives: {
    ClickOutside
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isSearchActive: false,
    }
  },
  computed: {
    search: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    searchOpen: function () {
      this.isSearchActive = true
      this.focusOnInput()
    },
    searchClose: function () {
      this.$emit('close')

      this.isSearchActive = false
      this.search = ''
    },
    searchOutsideClick: function () {
      if (this.search.length === 0) {
        this.searchClose()
      }
    },
    focusOnInput: function () {
      this.$nextTick(() => {
        this.$refs.searchRef.focus()
      })
    }
  }
}
</script>

<style lang="less">
  .stripe-panel-search {
    display: flex;
    width: 100%;
    height: 36px;

    .stripe-panel-search-input {
      display: flex;
      width: 100%;
      position: relative;
      cursor: pointer;

      label {
        vertical-align: middle;
        margin-top: 6px;
        margin-right: 10px;
        cursor: pointer;
      }
      input {
        background-color: transparent;
        border: none;
        padding: 10px 20px 10px 0;
        color: #fff;
        width: 100%;
        font-size: 16px;
      }
      .menu-close {
        position: absolute;
        right: 2px;
        top: 2px;
      }
    }

    .stripe-panel-search-other {
      margin-left: auto;
      display: flex;

      * {
        white-space: nowrap;
      }
    }
  }
</style>
