<template>
  <div class="modal small" v-body-scroll-lock="true">
    <div v-cloak class="modal-content">
      <div @click="$emit('close')" class="menu-close" data-dismiss="modal" aria-label="Close">
        <span class="menu-close-icon" />
      </div>
      <div class="modal-content-section">
        <h3 class="modal-content-title">
          Beneficiary details
        </h3>

        <div class="modal-content-inner">
          <div class="grid">
            <div class="col-6">
              <p class="modal-inner-title">
                Bank Account Holder Name
              </p>
              <p class="modal-inner-copy notranslate">
                {{ beneficiary.bank_account_holder_name }}
              </p>
            </div>

            <div class="col-6" v-if="beneficiary.currency">
              <p class="modal-inner-title">
                Currency
              </p>
              <p class="modal-inner-copy notranslate">
                {{ beneficiary.currency.id }} ({{ beneficiary.currency.name }})
              </p>
            </div>

            <div class="col-6" v-if="beneficiary.bankCountry">
              <p class="modal-inner-title">
                Bank country
              </p>
              <p class="modal-inner-copy">
                {{ beneficiary.bankCountry.label }}
              </p>
            </div>

            <div class="col-6">
              <p class="modal-inner-title notranslate">
                {{ labelForIban }}
              </p>
              <p class="modal-inner-copy notranslate">
                {{ beneficiary.iban }}
              </p>
            </div>

            <div class="col-6">
              <p class="modal-inner-title notranslate">
                {{ labelForBic }}
              </p>
              <p class="modal-inner-copy notranslate">
                {{ beneficiary.bic_swift }}
              </p>
            </div>

            <div class="col-6" v-if="beneficiary.address">
              <p class="modal-inner-title">
                Street
              </p>
              <p class="modal-inner-copy notranslate">
                {{ beneficiary.address.street }}
              </p>
            </div>

            <div class="col-6" v-if="beneficiary.address">
              <p class="modal-inner-title">
                House
              </p>
              <p class="modal-inner-copy notranslate">
                {{ beneficiary.address.house }}
              </p>
            </div>

            <div class="col-6" v-if="beneficiary.address">
              <p class="modal-inner-title">
                Country
              </p>
              <p class="modal-inner-copy notranslate">
                {{ beneficiary.address.country.label }}
              </p>
            </div>

            <div class="col-6" v-if="beneficiary.address">
              <p class="modal-inner-title">
                City
              </p>
              <p class="modal-inner-copy notranslate">
                {{ beneficiary.address.city }}
              </p>
            </div>

            <div class="col-6" v-if="beneficiary.address">
              <p class="modal-inner-title">
                Postal code
              </p>
              <p class="modal-inner-copy notranslate">
                {{ beneficiary.address.postal_code }}
              </p>
            </div>

            <div class="col-6">
              <p class="modal-inner-title">
                Type
              </p>
              <p class="modal-inner-copy notranslate">
                {{ isCompany ? 'Business' : 'Individual' }}
              </p>
            </div>

            <div v-if="!isCompany" class="col-6">
              <p class="modal-inner-title">
                First name
              </p>
              <p class="modal-inner-copy notranslate">
                {{ beneficiary.beneficiary_first_name }}
              </p>
            </div>

            <div v-if="!isCompany" class="col-6">
              <p class="modal-inner-title">
                Last name
              </p>
              <p class="modal-inner-copy notranslate">
                {{ beneficiary.beneficiary_last_name }}
              </p>
            </div>

            <div v-if="isCompany" class="col-6">
              <p class="modal-inner-title">
                Company name
              </p>
              <p class="modal-inner-copy notranslate">
                {{ beneficiary.beneficiary_company_name }}
              </p>
            </div>

            <div class="col-6">
              <p class="modal-inner-title">
                Template name
              </p>
              <p class="modal-inner-copy notranslate">
                {{ beneficiary.name }}
              </p>
            </div>
          </div>
        </div>

        <div class="modal-content-bottom-panel">
          <button type="button" v-if="permissions.canPaymentDraftCreate" @click.prevent="showCreateModalWindow(beneficiary.id)" class="default-button">Pay</button>
          <button type="button" v-else @click="close" class="next-form-button">
            <icon name="icp_close" />
            <span>Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentCreateModal from './PaymentCreateModal'
import {mapGetters} from "vuex";
import Icon from "../../libs/Icon";

export default {
  name: 'BeneficiaryDetailsModal',
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  components: {
    Icon
  },
  data () {
    return {
      beneficiary: {
        bankCountry: Object,
        currency: Object
      }
    }
  },
  created: function () {
    this.$sdk.beneficiary
        .find(this.id, ['bankCountry', 'address', 'address.country', 'currency'])
        .then(response => {
          this.beneficiary = response.data
        })
  },
  computed: {
    ...mapGetters(['permissions']),

    isCompany () {
      return this.beneficiary &&
             this.beneficiary.beneficiary_entity_type === 'company'
    },

    /**
     * Check is it local payment in GBP and bank country United Kingdom
     * @return {boolean}
     */
    isLocalPayment () {
      return this.beneficiary.bank_details_type === 'LOCAL'
    },

    labelForIban () {
      if (this.isLocalPayment) {
        return 'Account number'
      } else {
        return 'IBAN'
      }
    },

    labelForBic () {
      if (this.isLocalPayment) {
        return 'Sort Code'
      } else {
        return 'BIC/SWIFT'
      }
    },
  },
  methods: {
    showCreateModalWindow (id) {
      this.$emit('close')
      this.$modal.show(PaymentCreateModal, {
        beneficiaryId: id
      })
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
