<template>
  <div id="beneficiary" class="main-content">
    <div class="stripe-panel-wrapper">
      <search-input
          v-model.trim="search"
          :placeholder="'Enter beneficiary name, IBAN or currency'"
          @close="search = ''"
      >
        <button v-if="permissions.canBeneficiaryCreate"
                class="btn add-beneficiary highlighted"
                @click="showCreateBeneficiaryModal"
        >
          Add beneficiary
        </button>
      </search-input>
    </div>

    <div class="shadow-line">
      <div class="scrollbar-hide" style="height: calc(100vh - 157px); padding-bottom: 25px;">
        <div class="panel-wrapper">
          <div>
            <h1 class="headline">
              Beneficiary
            </h1>
          </div>
        </div>

        <div class="main-content-grid">

          <div class="grid" v-if="items.length > 0">
            <div class="col-4" v-for="item in items" :key="item.id">
              <beneficiary-card :item="item" />
            </div>
          </div>

          <infinite-loading :identifier="identifier" @infinite="loadMore">
            <template #no-results>
              <div class="main-content-grid-empty no-beneficiaries-results">Beneficiaries not found</div>
            </template>
            <template #no-more>
              <div class="main-content-grid-empty no-beneficiaries-more">No more beneficiaries</div>
            </template>
          </infinite-loading>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sortBy from 'lodash/sortBy'
import debounce from 'lodash/debounce'

import BeneficiaryCreateModal from './modals/BeneficiaryCreateModal'
import SearchInput from './beneficiary/SearchInput'
import {mapState, mapGetters} from "vuex";
import BeneficiaryCard from "./beneficiary/BeneficiaryCard";
import InfiniteLoading from 'vue-infinite-loading';

export default {
  components: {
    BeneficiaryCard,
    SearchInput,
    InfiniteLoading
  },
  data () {
    return {
      search: '',
      identifier: new Date(),
      page: 1,
      perPage: 20,
      beneficiaries: []
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.data
    }),
    ...mapGetters({
      permissions: 'permissions'
    }),
    items () {
      return sortBy(this.beneficiaries, ['createdAtMs']).reverse()
    }
  },
  watch: {
    search: debounce(function () {
      this.reload()
    }, 500)
  },
  sockets: {
    /**
     * Add new beneficiary to start of list
     *
     * @param id
     * @returns {Promise<void>}
     */
    async 'createBeneficiary' ({ id }) {
      let { data } = await this.getBeneficiary(id)
      this.beneficiaries.unshift(data)

      if (this.beneficiaries.length > this.perPage) {
        this.beneficiaries.splice(this.beneficiaries.length - 1, 1)
      }
    },

    /**
     * Update beneficiary
     *
     * @param id
     * @returns {Promise<void>}
     */
    async 'updateBeneficiary' ({ id }) {
      let { data } = await this.getBeneficiary(id)
      let index = this.beneficiaries.findIndex(i => parseInt(id) === parseInt(i.id))
      if (index >= 0) {
        this.$set(this.beneficiaries, index, data)
      }
    },

    /**
     * Delete beneficiary
     */
    'deleteBeneficiary' () { this.reload() },
  },
  methods: {
    getBeneficiary (id) {
      return this.$sdk.beneficiary.find(id, ['currency', 'address', 'address.country'])
    },

    reload () {
      this.page = 1
      this.beneficiaries = []

      this.$nextTick(() => this.identifier++)
    },

    /**
     *
     */
    showCreateBeneficiaryModal: function () {
      this.$modal.show(BeneficiaryCreateModal)
    },

    /**
     *
     * @param $state
     * @return {Promise<AxiosResponse<*>>}
     */
    loadMore ($state) {
      return this.$sdk.beneficiary.list({
        expand: ['currency', 'address', 'address.country'].join(','),
        page: this.page,
        search: this.search || undefined
      }).then(({ headers, data }) => {
        this.perPage = parseInt(headers['x-pagination-per-page'])

        if (Array.isArray(data) && data.length > 0) {
          this.beneficiaries.push(...data)
          $state.loaded()
        }

        this.page++

        let currentPage = parseInt(headers['x-pagination-current-page'])
        let maximumPage = parseInt(headers['x-pagination-page-count'])

        if (currentPage >= maximumPage) {
          $state.complete()
        }
      })
    }
  }
}
</script>

<style lang="less">
.no-beneficiaries {
  &-more {
    padding-top: 20px;
    color: rgba(255, 255, 255, .6);
  }
}
</style>
