<template>
  <ul class="tabs" :class="{disabled: this.disabled}">
    <li v-for="item in items" :key="item.id"
        :class="{active: hasActive(item)}"
        @click="click(item)"
    >
      <slot>{{ item.label }} <span v-if="item.count">{{ item.count }}</span> </slot>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    if (!this.value && this.items.length) {
      this.val = this.items[0]
    }
  },
  computed: {
    val: {
      set (val) {
        this.$emit('input', val)
      },
      get () {
        return this.value
      }
    }
  },
  watch: {
    'items': {
      handler (newVal) {
        const currentItem = newVal.find((item) => item.id === this.val.id)
        this.val = currentItem ?? null
      },
      deep: true
    }
  },
  methods: {
    hasActive (item) {
      return this.val
          ? this.val.id === item.id
          : false
    },

    click (item) {
      if (!this.disabled) {
        this.val = item
      }
    }
  }
}
</script>

<style lang="less">
.tabs {
  display: flex;
  flex-direction: row;
  grid-gap: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.09);

  &:not(.disabled) {
    li {
      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  li {
    padding-bottom: 20px;
    color: rgba(255, 255, 255, .5);
    border-bottom: 2px solid transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: all .3s ease;
    user-select: none;
    text-align: left;
    font-weight: 500;
    font-size: 15px;

    span {
      background-color: #2a2a2d;
      border-radius: 8px;
      padding: 0 8px;
      font-size: 13px;
      margin-left: 8px;
    }

    &.active {
      color: rgba(255, 255, 255, 1);
      border-color: #FDE46A;
    }
  }
}
</style>
